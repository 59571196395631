(function ($) {
  $(function () {
    //logo slider
    $(".autoslide").slick({
      slidesToShow: 5,
      slidesToScroll: 1,
      dots: false,
      arrows: false,
      autoplay: true,
      autoplaySpeed: 2000,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            centerMode: true,
            centerPadding: "100px",
          },
        },
      ],
    });

    //Post
    $(".slider-featured-post").slick({
      slidesToShow: 3,
      slidesToScroll: 1,
      dots: false,
      arrows: false,
      autoplay: false,
      autoplaySpeed: 2000,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            dots: true,
            centerMode: false,
          },
        },
        {
          breakpoint: 560,
          settings: {
            slidesToShow: 1,
            dots: true,
            centerMode: true,
            centerPadding: "65px",
          },
        },
      ],
    });

    // Business carousel
    $(".team-carousel-js").slick({
      slidesToShow: 4,
      slidesToScroll: 1,
      dots: false,
      arrows: true,
      autoplay: false,
      autoplaySpeed: 2000,
      prevArrow: $(".team-carousel-prev", this),
      nextArrow: $(".team-carousel-next", this),
      responsive: [
        {
          breakpoint: 1400,
          settings: {
            slidesToShow: 3,
            centerMode: false,
          },
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 2,
            centerMode: false,
          },
        },
        {
          breakpoint: 560,
          settings: {
            slidesToShow: 1,
            centerMode: true,
          },
        },
      ],
    });
    //Hide carousel nav if less than 5 team members (as there are 4 items to scroll)
    if ($(".team-carousel-js .slick-slide").length < 5) {
      $(".team-carousel-nav").addClass("d-none");
    }
  });
})(jQuery);
